import React from "react"
import { Helmet } from "react-helmet-async"

import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import ListingPageContent from "../components/listingPageContent/listingPageContent"
import BigReadingCard from "../components/moreReading/bigReadingCard"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import { parseMarket } from "../consts/markets"
import { getHreflang, getPagePath } from "../helpers/getHreflang"
import { useTranslation } from "../helpers/useTranslation"
import { generateTranslations } from "../helpers/generateTranslations"

import * as styles from "./case-study.module.scss"

const translations = generateTranslations("case-studies")

const CaseStudyListContent = props => {
  const { data, pageContext, location } = props
  const { content, seo } = data.sanityCsListing.tabs
  const categories = data.allSanityCsCategory
  const posts = data.allSanityCs
  const { currentPage, totalPages, market, categorySlug } = pageContext
  const link = market === "en-us" ? "case-studies" : `${parseMarket(market)}/case-studies`
  const pagePath = getPagePath()
  const hreflangAbbr = getHreflang()
  const { t } = useTranslation()

  const renderItem = (item, idx) => {
    const itemProps = {
      description: item?.tabs?.csListingPreview?.excerpt || "",
      logo: item?.tabs?.csListingPreview?.logo || item?.tabs?.content?.hero?._rawBackgroundImage,
      title: item?.tabs?.content?.title || "",
      page: item,
      label: item?.tabs?.csListingPreview?.label,
    }

    if (!itemProps.logo?.asset || !itemProps.title) return null

    return <BigReadingCard key={idx} {...itemProps} imageFullWidth />
  }

  const renderItems = items => {
    return <div className={styles.list}>{(items?.nodes || []).map(renderItem)}</div>
  }
  return (
    <Layout translations={translations}>
      <Helmet>
        <link rel="alternate" href={pagePath} hreflang={hreflangAbbr} />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/case-studies", "/au/case-studies")
              : market === "en-au"
              ? pagePath.replace("/au/case-studies", "/es/case-studies")
              : market === "en-us"
              ? pagePath.replace("/case-studies", "/au/case-studies")
              : market === "pt"
              ? pagePath.replace("/pt/case-studies", "/case-studies")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "en-au"
              : market === "en-au"
              ? "es"
              : market === "en-us"
              ? "en-au"
              : market === "pt"
              ? "en"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/case-studies", "/case-studies")
              : market === "en-au"
              ? pagePath.replace("/au/case-studies", "/case-studies")
              : market === "en-us"
              ? pagePath.replace("/case-studies", "/es/case-studies")
              : market === "pt"
              ? pagePath.replace("/pt/case-studies", "/es/case-studies")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "en"
              : market === "en-au"
              ? "en"
              : market === "en-us"
              ? "es"
              : market === "pt"
              ? "es"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/case-studies", "/pt/case-studies")
              : market === "en-au"
              ? pagePath.replace("/au/case-studies", "/pt/case-studies")
              : market === "en-us"
              ? pagePath.replace("/case-studies", "/pt/case-studies")
              : market === "pt"
              ? pagePath.replace("/pt/case-studies", "/au/case-studies")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "pt"
              : market === "en-au"
              ? "pt"
              : market === "en-us"
              ? "pt"
              : market === "pt"
              ? "en-au"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/case-studies", "/case-studies")
              : market === "en-au"
              ? pagePath.replace("/au/case-studies", "/case-studies")
              : market === "pt"
              ? pagePath.replace("/pt/case-studies", "/case-studies")
              : pagePath
          }
          hreflang="x-default"
        />
      </Helmet>
      <Seo
        title={content.hero}
        breadcrumbs={[
          {
            link,
            title: "Case Studies",
          },
        ]}
        path={location.pathname}
        {...seo}
      />
      <Breadcrumbs homePageLabel="case studies" steps={[{ label: t("case studies") }]} />
      <ListingPageContent
        title={content.hero}
        subtitle={content.subtitle}
        items={posts}
        background="none"
        renderItems={renderItems}
        currentPage={currentPage}
        totalPages={totalPages}
        categorySlug={categorySlug}
        categories={categories}
        baseUrl={link}
      />
    </Layout>
  )
}

export default CaseStudyListContent
