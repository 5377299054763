export const generateTranslations = slug =>
  ["en-us", "es", "au", "pt"].map(market => ({
    tabs: {
      content: {
        market,
        slug: {
          current: slug,
        },
      },
    },
  }))
